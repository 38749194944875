import React from "react";
import Layout from "../components/layout";
import ProjectCard from "../components/projectCard";
import Footer from "../components/footer";
import { StaticImage } from "gatsby-plugin-image";

const Projects = () => {
  return (
    <div>
      <Layout
        header={<h2>PROJECTS</h2>}
        content={
          <div>
            {/* bu projeyi şimdilik çıkartıyorum
            <div className="project-card">
              <StaticImage
                className="project-image"
                src="../images/oruba.png"
                alt="Oruba"
                objectFit="contain"
                imgStyle={{ objectPosition: "0,0" }}
              />
              <ProjectCard
                name="UX/UI for IOT 
                Healthcare Solutions"
                tag="UX / UI DESIGN"
                description="Oruba Medical develops connected product solutions for urology departments. I worked with them in close collaboration to redesign their product interfaces, user flows, web experience and branding."
              ></ProjectCard>
            </div>
            */}
            <div className="project-card">
              <StaticImage
                className="project-image"
                src="../images/wildwatch.png"
                alt="Oruba"
                layout="constrained"
              />
              <ProjectCard
                name="A birdwatcher's adventure"
                tag="GAME DESIGN"
                description="Wildwatch is a 2D stealth/adventure game. The main character is a birdwatcher trying to take pictures without getting noticed by birds. "
              ></ProjectCard>
            </div>

            <div className="project-card">
              <StaticImage
                className="project-image"
                src="../images/refugee.png"
                alt="Refugee"
              />
              <ProjectCard
                name="Reimagining refugee registration process"
                tag="UX RESEARCH"
                description="The aim of the project was improving Syrian Refugees' registration process from design perspective. The process involved field observations, interviews and persona creation which led to a creative workshop to propose improvements for the immigration offices."
              ></ProjectCard>
            </div>

            <div className="project-card">
              <StaticImage
                className="project-image"
                src="../images/bike.png"
                alt="Bike"
              />
              <ProjectCard
                name="Bike computer usability tests and redesign"
                tag="UX RESEARCH / UX DESIGN"
                description="As a part of the Usability and User Experience Assessment in Design (UXAD) course offered at TU Delft, as a team, we put Sigma BC 1909 bike watch into multiple user tests, redesigned based on our findings and tested again to validate our design. "
              ></ProjectCard>
            </div>

            <div className="project-card">
              <StaticImage
                className="project-image"
                src="../images/arbor.png"
                alt="Arbor"
              />
              <ProjectCard
                name="Interactive light installation"
                tag="INTERACTION DESIGN / INTERACTIVE PROTOTYPING"
                description="Arboresthesia is an interactive installation aiming to draw attention to recyclable waste generated in medical operations. It was presented at the Turkish Anesthesiology and Reanimation Assosication's National Assembly in 2018 and got media coverage. "
              ></ProjectCard>
            </div>
          </div>
        }
      ></Layout>
      <Footer></Footer>
    </div>
  );
};

export default Projects;
