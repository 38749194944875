import React from "react";
import "../styles/global.css";

const ProjectCard = (props) => {
  return (
    <div className="project-card-content">
      <p className="small-text">{props.tag}</p>
      <h1>{props.name}</h1>
      <p className="medium-text">{props.description}</p>
    </div>
  );
};

export default ProjectCard;
